<template>
  <div>
    <app-api-info
      :api-info="apiError"
      class="my-3"
    />
    <v-autocomplete
      v-model="companyItemsModel"
      :items="companiesItems"
      :loading="isLoadingCompanyItems"
      :search-input.sync="searchCompanyItems"
      :label="$i18n.t('$l.company.searchByBusinessIdOrName')"
      :filter="companyFilter"
      prepend-icon="mdi-database-search"
      item-text="description"
      return-object
      :disabled="disabled"
    >
      <template
        slot="item"
        slot-scope="{ item }"
      >
        <p
          v-if="item.nametoiso"
          class="grey--text text-decoration-line-through"
        >
          {{ item.description + ' - ' + $i18n.t('$l.app.nameUntil') + ' ' + item.nametoiso }}
        </p>
        <p
          v-else-if="item.icotoiso"
          class="grey--text text-decoration-line-through"
        >
          {{ item.description + ' - ' + $i18n.t('$l.app.icoUntil') + ' ' + item.icotoiso }}
        </p>
        <p
          v-else
        >
          {{ item.description }}
        </p>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  name: 'CompanyAutocomplete',
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      apiError: null,
      companyItemsModel: null,
      isLoadingCompanyItems: false,
      searchCompanyItems: null,
      companies: [],
      loadingAddress: false,
      autocompletePromise: null,
      company2: null
    }
  },
  computed: {
    companiesItems () {
      return this.companies.map(entry => {
        if (entry === null) return null
        const description = entry.description && entry.description.length > 0 ? entry.description : entry.ico + (entry.name.length > 0 ? ' (' + entry.name + ')' : '')
        return Object.assign({}, entry, { description })
      })
    }
  },
  watch: {
    searchCompanyItems: debounce(function (val) {
      this.apiError = null
      if (!val || val === null) return
      if (this.companyItemsModel && val === this.companyItemsModel.description) return
      if (!val || val.length < 3) return
      this.isLoadingCompanyItems = true
      this.autocompletePromise = this.$xapi.get('endpointsSearchCompanies/businessIdOrName/' + val)
      this.autocompletePromise
        .then(r => {
          this.companies = r.data
          if (r.data.length < 1) {
            this.companies = []
          }
        })
        .catch(e => {
          this.companies = [{ 'ico': '', 'name': e.response.localizedMessage }]
        })
        .finally(() => {
          this.isLoadingCompanyItems = false
        })
    }, 500),
    companyItemsModel (val) {
      this.apiError = null
      if (val) {
        this.loadingAddress = true
        this.$emit('loading-address', this.loadingAddress)
        let company = null
        this.$xapi.get('endpointsSearchCompanies/externalId/' + val.externalId)
          .then(r => {
            company = r.data
            company.ico = val.ico
            this.$emit('on-selection', company)
          })
          .catch(e => {
            this.apiError = e.response.localizedMessage
          })
          .finally(() => {
            if (company && company.terminated) {
              this.apiError = this.$i18n.t('$l.company.terminationWarning') + ' ' + company.terminated
            }
            this.loadingAddress = false
            this.$emit('loading-address', this.loadingAddress)
          })
      }
    }
  },
  methods: {
    companyFilter (item, queryText) {
      return true
    }
  }
}
</script>
